import throttle from 'lodash/throttle';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['toggle', 'animatedLogo'];

  get modal() {
    return document.querySelector('.nav-menu');
  }

  get closeBtn() {
    return document.querySelector('.nav-menu__close--btn');
  }

  get firstFocusableElement() {
    return this.closeBtn;
  }

  get lastFocusableElement() {
    return document.querySelector('.nav-menu__list li:last-child a');
  }

  get isScrolled() {
    return document.body.classList.contains('scrolled');
  }

  initialize() {
    this.state = {
      isOpen: false,
    };
    let $this = this;
    window.addEventListener('load', this.initLogoAnimation.bind(this));

    this.closeBtn.addEventListener('click', this.closeMenu.bind(this));

    this.modal.addEventListener('keydown', (e) => {
      if (!$this.state.isOpen) return;
      if (e.key === 'Escape') $this.closeModal();
      if (e.key !== 'Tab') return;
      if (!e.shiftKey) {
        if (document.activeElement === $this.lastFocusableElement) {
          $this.firstFocusableElement.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === $this.firstFocusableElement) {
          $this.lastFocusableElement.focus();
          e.preventDefault();
        }
      }
    });
  }

  applyLogoAnimation() {
    document.body.classList.add('animate-logo');
  }

  removeLogoAnimation() {
    document.body.classList.remove('animate-logo');
  }

  initLogoAnimation() {
    if (!document.body.classList.contains('animate-logo')) return;

    if (window.scrollY > 0) {
      document.body.classList.add('scrolling');
    }

    window.addEventListener('scroll', throttle(() => {
      document.body.classList.add('scrolling');
    }, 250));

    setTimeout(() => {
      document.body.classList.remove('no-transition');
    }, 50);

    this.animatedLogoTarget.classList.add('fadeIn');
  }

  openMenu(e) {
    if (e) e.stopPropagation();
    // focus after nav menu has animated
    setTimeout(() => this.closeBtn.focus(), 250);
    document.body.classList.add('nav-menu-open');
    this.state.isOpen = true;
    this.bodyClickHandler = this.closeModal.bind(this);
    document.body.addEventListener('click', this.bodyClickHandler);
  }

  closeModal(e) {
    if (!this.modal.contains(e.target)) this.closeMenu();
  }

  closeMenu() {
    document.body.classList.remove('nav-menu-open');
    this.state.isOpen = false;
    this.toggleTarget.focus();
    document.body.removeEventListener('click', this.bodyClickHandler);
  }

}
