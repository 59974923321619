import { Controller } from 'stimulus'

export default class extends Controller {
  get panels() {
    return document.querySelectorAll('.footer__content');
  }

  get controls() {
    return document.querySelectorAll('.footer__control');
  }

  initialize() {
    this.panels.forEach(panel => this.setMaxHeight(panel));
  }

  toggle(e) {
    let controls = e.currentTarget;
    let bool = controls.getAttribute('aria-expanded') === 'true' ? 'false' : 'true';
    this.setControls(controls, bool);
  }

  setControls(controls, bool) {
    let panel = document.getElementById(controls.getAttribute('aria-controls'));
    controls.setAttribute('aria-expanded', bool);
    this.setMaxHeight(panel);
    if (bool === 'true') {
      this.controls.forEach(control => {
        if (control !== controls) {
          this.setControls(control, 'false');
        }
      })
    }
  }

  setMaxHeight(panel) {
    let controls = document.querySelector(`[aria-controls="${panel.id}"]`)
    let bool = controls.getAttribute('aria-expanded') !== 'true';
    let height = panel.scrollHeight + 40;
    panel.style.maxHeight = bool ? null : height + 'px';
  }

}
